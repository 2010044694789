function initBraintree(ID, threeDSecureParameters, threeDSecure = true){
    return new Promise((resolve, reject) => {
        braintree.dropin.create({
            authorization: ID,
            container: '#dropin-container',
            threeDSecure: threeDSecure,
            paypal: {
                flow: 'vault',
                buttonStyle: {
                    color: 'blue',
                    shape: 'rect',
                    size: 'medium'
                }
            }
        }, function (err, dropinInstance) {
            if (err) {
                // Handle any errors that might've occurred when creating Drop-in
                reject(err);
            }
            var submitButton = document.querySelector('#submit-button');
            submitButton.addEventListener('click', function () {
                dropinInstance.requestPaymentMethod({
                        threeDSecure: threeDSecureParameters
                    },function (err, payload) {
                    if (err) {
                        // Handle errors in requesting payment method
                        console.log(err);
                        reject(err);
                    }
                    resolve(payload);
                    // Send payload.nonce to your server
                });
            });
        });
    });
}
